export const instructorsList = [
    {
        id: 1,
        name: 'Ciarán Horgan',
        subHead: 'Branch Head and Head Instructor',
        profile: [
            'Ciarán Horgan Sensei is a 4th Dan Japan Karate Association (JKA) Instructor qualified with the most established and reputable Budo karate organisation, the Japan Karate Association (JKA), a Public Interest Incorporated Association in Japan.',
            'His practice of Karate spans in excess of 30 years and he has studied and practised karate with the most reputable masters of Karate, including Teruyuki Okazaki Sensei (10th Dan), Nishimura Takaatsu Shihan (7th Dan) and Masaaki Ueki Shuseki Shihan (10th Dan - Chief Instructor of JKA).',
            'Ciarán competed in kumite and kata in the 1994 JKA World Championships (5th World Shoto Cup) in Philadelphia and also competed in the Pan American games in 1995 in Toronto, Canada. Ciarán was a Director / Coach of the Australian National team competing in the 14th Funakoshi Gichin Cup in Ireland in 2017 and was a Coach of the Australian National Team competing in the Asia Oceania Championships in Thailand in 2019.',
            'Ciarán is a former judo practitioner and has also trained in weapons including the Bo, Sai and Nunchaku. His judo background lends a unique self-defence dimension to training at Ciarán’s dojo.',
            'Ciarán is friendly, patient, and approachable. While adhering to the JKA syllabus he accommodates the unique needs of all kareteka. He strongly believes that Karate should teach you more than just self-defence, but a way of life. He teaches that students should embody the precepts of Karate that JKA promotes.',
        ],
        imagebig: 'assets/instructor-ciaran-big.png',
        imagesmall: 'assets/instructor-ciaran-small.png',
        video: 'assets/instructor-ciaran-video.mov',
        attributes: ['4th Dan', 'Instructor B', 'Examiner D', 'Judge C'],
    },
    {
        id: 2,
        name: 'Jinesh Wilmot',
        subHead: 'Branch Instructor',
        profile: [
            'Jinesh Wilmot Sensei is a 3rd Dan Japan Karate Association (JKA) Instructor.',
            'Jinesh commenced his Shotokan karate training in Denmark in 1973, after training for a year in Jiu Jitsu.',
            'In Denmark, Jinesh practised JKA Shotokan karate under the then world champion Tanaka Masahiko (8th Dan), and Bura Larson (8th Dan), current Technical Director JKA Europe, achieving 2nd Dan.',
            'Jinesh competed as a member of the Danish national team representing Denmark at both European and World Championships, also achieving 3rd place in both kumite (fighting) and kata (Forms) at the Danish national championships.',
            'Jinesh later trained with Kanazawa Hirokazu Soke (10th Dan, SKIF) in Denmark and Australia, achieving a 3rd Dan in that organisation.',
            'After a long hiatus from karate, Jinesh returned to JKA karate in 2019, joining the Thornbury Dojo under Ciarán Horgan Sensei, subsequently training under Nishimura Takaatsu Shihan (7th Dan), and being graded to 3rd Dan, as well as receiving an Instructor (D) and Judge (D) licence (JKA).',
            'Jinesh feels that karate can be a life-long practice and encourages students to work according to their physical abilities, while endeavouring to strengthen their understanding and practice of all aspects of JKA Karate. ',
        ],
        imagebig: 'assets/instructor-jinesh-big.png',
        imagesmall: 'assets/instructor-jinesh-small.png',
        video: 'assets/instructor-jinesh-video.mov',
        attributes: ['3rd Dan', 'Instructor D', 'Judge D'],
    },
]
